export const CODE_API_BASE = `${API_BASE_CHECKOUT_CODE_UPDATE}`
export const USER_UPDATE_API_BASE = `${API_BASE_USER_UPDATE}`
export const GET_USER_BASE = `${API_BASE_USER_GET}`
export const CODE_EXCHANGE = `https://${CODE_API_BASE}/promohub/api/v1/codes/exchange`
export const PRIZES_EXCHANGE = `https://${CODE_API_BASE}/promohub/api/v1/prizes/exchange`

// -- PROFILE --
export const AUTH = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/login`
export const VERIFY = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/verify/email`
export const SEND_RESET = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/send/passwordreset`
export const VERIFY_RESET = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/verify/passwordreset`
export const DO_RESET = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/passwordreset`
export const GENRES = `https://${GET_USER_BASE}/promohub/api/v1/profiles/genres`
export const CHECK_ZIP = `https://${GET_USER_BASE}/promohub/api/v1/adresses/states`
export const SOCIAL_CALLBACK = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/social/callback/`
export const EXTERNAL_CALLBACK = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/verify/external`

export const GET_PRIZES = `https://${GET_USER_BASE}/promohub/api/v1/prizes`
export const GET_PRIZE_WARE_HOUSE =`https://${GET_USER_BASE}/promohub/api/v1/prizes/:id/consumptioncenters`
export const GET_LEVELS = `https://${GET_USER_BASE}/promohub/api/v1/profiles/levels`
export const GET_REFERRALS = `https://${GET_USER_BASE}/promohub/api/v1/profiles/referrals`
export const GET_RAFFLES = `https://${GET_USER_BASE}/promohub/api/v1/sorteos/prizes`
export const GET_RAFFLES_WINNERS = `https://${GET_USER_BASE}/promohub/api/v1/sorteos/winners`
export const UPDATE_ADDRESS = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/address`
export const UPDATE_USER = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles`
export const UPDATE_CONSUMPTION = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/consumption-center`
export const HISTORY = `https://${API_BASE_USER_GET}/promohub/api/v1/prizes/history`
export const TYC = `https://${API_BASE_USER_GET}/promohub/api/v1/pages/terms-and-conditions`
export const FAQS = `https://${API_BASE_USER_GET}/promohub/api/v1/pages/faqs`
export const PAGESWITHKEY = `https://${API_BASE_USER_GET}/promohub/api/v1/pages/:key`
export const SEND_EMAILVERIFY = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/send/emailverify`
export const TRIVIA_INTERESTS = `https://${API_BASE_USER_GET}/promohub/api/v1/trivias/interests`
export const TRIVIA_SET_INTEREST = `https://${API_BASE_USER_UPDATE}/promohub/api/v1/profiles/interests`
export const TRIVIA_LIST = `https://${API_BASE_USER_GET}/promohub/api/v1/trivias/list`
export const TRIVIA_FETCH = `https://${CODE_API_BASE}/promohub/api/v1/trivias`

export const TRIVIA_VALIDATE = `https://${CODE_API_BASE}/promohub/api/v1/trivias/:triviaId/validate/:isdirectaccess`
export const TRIVIA_PLAY = `https://${CODE_API_BASE}/promohub/api/v1/trivias/:triviaId/play/:isdirectaccess`
export const TRIVIA_ANSWER = `https://${CODE_API_BASE}/promohub/api/v1/trivias/:triviaId/answer`
export const TRIVIA_RANKING = `https://${API_BASE_USER_GET}/promohub/api/v1/trivias/ranking/:triviaId`

export const CATEGORY_PRIZES = `https://${API_BASE_USER_GET}/promohub/api/v1/prizes/category`
export const GET_RANKS = `https://${API_BASE_USER_GET}/promohub/api/v1/ranks`
export const POST_SUSCRIBE_RANKS = `https://${API_BASE_CHECKOUT_CODE_UPDATE}/promohub/api/v1/rank`
export const OTP_GENERATE = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/send/smsverify`
export const OTP_VALIDATE = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/verify/otp/phone`
export const UPDATE_PHONE = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/phone/:phone`
export const UPDATE_NICKNAME = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/nickname/:nickname`
export const INSTAGRAM = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/instagram`
export const OTP_VALIDATE_EMAIL = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/verify/otp/email`
// -- SURVEY --
export const GET_ALL_SURVEY = `https://${USER_UPDATE_API_BASE}/api/survey/list`
export const SURVEY_VALIDATE = `https://${USER_UPDATE_API_BASE}/api/survey/:surveyId/validate/:isdirectaccess`
export const SURVEY_GET_RESPONSE = `https://${USER_UPDATE_API_BASE}/api/survey/:surveyId/result`
export const PUT_SURVEY = `https://${USER_UPDATE_API_BASE}/api/survey/:surveyId/answer`

export const GET_CARRUSEL = `https://${GET_USER_BASE}/promohub/api/v1/configuration/banners/list`
export const POST_REFERRAL = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/referral`
export const SOCIAL_LOGIN = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/login/social`
export const PUT_CONTACT = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/contact`
export const PUT_AUTH_INSTAGRAM = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/callback/instagram`
export const VERIFY_NICKNAME = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/verify/nickname`
export const VERIFY_REFERRALCODE = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/profiles/verify/referralcode`
export const COLLECTION_STICKER_EXCHANGE = `https://${API_BASE_CHECKOUT_CODE_UPDATE}/promohub/api/v1/collections/sticker/exchange`
export const COLLECTION_STICKER_MULTIMEDIA = `https://${API_BASE_CHECKOUT_CODE_UPDATE}/promohub/api/v1/collections/sticker/multimedia`
export const COLLECTION_GET = `https://${GET_USER_BASE}/promohub/api/v1/collections`

// -- ALBUM --

export const ALBUM_GET = `https://${GET_USER_BASE}/promohub/api/v1/rally/getall`
export const ALBUM_GET_ID = `https://${GET_USER_BASE}/promohub/api/v1/rally/get?id=`
export const ALBUM_GET_PROFILE = `https://${GET_USER_BASE}/promohub/api/v1/rally/getforuser`
export const ALBUM_VALIDATE = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/rally/{ID}/validate`
export const ALBUM_VOTE_MULTIMEDIA = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/rally/vote`
export const ALBUM_VALIDATE_TEXT = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/rally/{ID}/validatetext`
export const ALBUM_GET_RANK_USER = `https://${GET_USER_BASE}/promohub/api/v1/rally/getrallyrankforuser`

// -- --
export const GET_MARKETPLACE = `https://${GET_USER_BASE}/promohub/api/v1/configuration/marketplaces/list`
export const POST_PHOTOGRAPHER = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/rally/{ID}/multimedia`
export const POST_PHOTOGRAPHER_URL = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/rally/{ID}/multimediaurl`
export const POST_PHOTOGRAPHER_WITH_TEXT = `https://${USER_UPDATE_API_BASE}/promohub/api/v1/rally/{ID}/text`
export const GET_PHOTOGRAPHER_ACTIVE = `https://${GET_USER_BASE}/promohub/api/v1/rally/active`
export const GET_PHOTOGRAPHER_WINNERS = `https://${GET_USER_BASE}/promohub/api/v1/rally/winners`
export const PRIVACYPOLITICSMX = `https://api-mx-marketing.azure-api.net/sitios/privacidad/aviso`
export const PRIVACYPOLITICSBACK = `https://${API_BASE_USER_GET}/promohub/api/v1/pages/privacy`
export const COOKIESMX = `https://api-mx-marketing.azure-api.net/sitios/cookies/aviso/V1`
export const COOKIESBACK = `https://${API_BASE_USER_GET}/promohub/api/v1/pages/cookies`
export const GETSTATELIST = `https://${GET_USER_BASE}/promohub/api/v1/adresses/state/list`
export const GETCITYLIST = `https://${GET_USER_BASE}/promohub/api/v1/adresses/city/list`
export const GETCENTERLIST = `https://${GET_USER_BASE}/promohub/api/v1/adresses/center/list`
export const GET_MOVEMENTS = `https://${GET_USER_BASE}/promohub/api/v1/profiles/movements`
export const GET_MOVEMENTS_DETAILS = `https://${GET_USER_BASE}/promohub/api/v1/profiles/movements/:categoryId`

// -- MEMORAMA --

export const MEMORAMA_GET = `https://${GET_USER_BASE}/promohub/api/v1/memoramas/list`
export const MEMORAMA_GET_ONE = `https://${API_BASE_CHECKOUT_CODE_UPDATE}/promohub/api/v1/memoramas/:id`
export const MEMORAMA_ADVANCE = `https://${API_BASE_CHECKOUT_CODE_UPDATE}/promohub/api/v1/memoramas/advance`
export const MEMORAMA_STATUS = `https://${API_BASE_CHECKOUT_CODE_UPDATE}/promohub/api/v1/memoramas/:id/status/:status`

// -- HISTORY --

export const HISTORY_TRACKER = `https://${CODE_API_BASE}/promohub/api/v1/prizes/order/:historyId/otp`

// -- CLIENT CODE --

export const CLIENT_CODE = `https://${API_BASE_CHECKOUT_CODE_UPDATE}/api/consumptions/find/:clientNum`

// -- WALLET --

export const WALLET_STATUS = `https://${USER_UPDATE_API_BASE}/api/wallet/status/:type`
export const WALLET_CREATE = `https://${USER_UPDATE_API_BASE}/api/wallet/create/:type`

// -- MENU --

export const GET_MENU = `https://${API_BASE_USER_GET}/promohub/api/v1/menu/active`

// -- SOCIAL --
export const GET_LINKEDACCOUNTS = `https://${API_BASE_USER_GET}/promohub/api/v1/profiles/linksaccount`

// -- WAREHOUSE -- 
export const GET_WARE_HOUSES = `https://${API_BASE_USER_GET}/promohub/api/v1/consumptioncenters/list`

// -- Lineups --
export const GET_EVENTS = `https://${API_BASE_USER_GET}/promohub/api/v1/event/active`
export const GET_LINEUPS = `https://${API_BASE_USER_GET}/promohub/api/v1/event/lineup/:id`
export const GET_LINEUPS_PROFILE = `https://${API_BASE_USER_GET}/promohub/api/v1/event/lineup/:id/profile`
export const UPDATE_LINEUPS_PROFILE = `https://${USER_UPDATE_API_BASE}/api/event/lineup`