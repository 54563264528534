/* eslint-disable node/no-callback-literal */
import { AUTH } from 'constants/api'
import { useContext, useState } from 'react'
import { AuthError, GoogleAuthProvider, getAuth, OAuthCredential, signInWithPopup } from '@firebase/auth'
import axios, { AxiosError } from 'axios'
import utmServices from 'services/utm-services'
import ProfileService from 'services/profile-services'
import { UserData } from 'contexts/session-context/session-reducer'
import GoogleIcon from 'components/icon/google'
import { dlSetUserInfo, dlTrackEvent, createUserProperties } from 'components/dataLayer/dataLayer'
import useRedirect from 'hooks/use-redirect'
import useLocalStorage from 'hooks/use-local-storage'
import { isInAppBrowser } from 'utilities/detectDeviceAgent'
import { getBirthdayLocal } from 'utilities/birthday'
import MetaCAPIService from 'services/meta-capi.services'
import { Prize } from 'views/prizes/types'
import useRouter from 'hooks/use-router'
import textServices from 'services/text-services'
import CONFIGS from 'build/Config'
import AlertAppView from '../alertAppView'
import { LoaderContext } from '../../../contexts/loader-context'
import { goToUrlGoogle } from './goToUrlGoogle'

function Google({ setSession, setUser, setLoginError, setError, setFirebaseError, setAllowCookies, setPrize }) {
  const { setLoad } = useContext(LoaderContext)
  const [{ url }, { redirect }] = useRedirect()
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  const [flowType] = useLocalStorage('flowType')
  const [alertAppView, setAlertAppView] = useState(false);
  const [birthday] = useState(getBirthdayLocal())
  const { push } = useRouter();

  const completeData = (user: UserData, session: Object) => {
    setSession(session)
    setUser(user)

    const external = localStorage.getItem('toexternal')
    if (external) {
      localStorage.removeItem('toexternal')
      return window.location.assign(decodeURI(JSON.parse(external)))
    };


    if (url) return redirect();

    const availableFields = CONFIGS?.notAvailableFields
    const isComplete = ((availableFields?.includes('nickName') ? false : !user?.nickName) || !user?.birthday || (!user?.genre || !user?.genreId))
    if (!CONFIGS?.signUp?.base && isComplete) return push('/information/update?isNew=true');

    return push('/home')
  }

  const callback = async (data?: {
    termsOfUse?: boolean
    subscribe?: boolean
    code?: string
    session: Object
    user: UserData
    prize?: Prize
  }) => {
    setLoad(true)
    const { session, user, termsOfUse, subscribe, code, prize } = data || {}
    try {
      dlSetUserInfo(user?.hashId ?? user?.id)
      createUserProperties(user, 'google')
      let payload = { termsOfUse, subscribe, code }
      const consumption = localStorage.getItem('consumption')

      if (consumption) {
        payload = { ...payload, consumptionCenterId: JSON.parse(consumption) ?? 0 } as any
      }

      let userData = user
      if (termsOfUse || subscribe || code) {
        const response = await ProfileService.socialLogin({
          payload: payload,
          credentials: session
        })

        userData = response?.data
      }

      if (prize) return setPrize({ prize, name: userData?.firstName, callback: () => completeData(userData, session) })

      return completeData(userData, session)
    } catch (error) {
      setAllowCookies({ callback, isAllow: false })
      const code = (error as AxiosError).response?.data.code || 99999
      setLoginError(code)
    } finally {
      setLoad(false)
    }
  }

  const login = async () => {
    setLoad(true)
    try {
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      const result = await signInWithPopup(auth, provider)
      const names = result.user?.displayName?.split(' ') || ['', '']
      const firstName = names[0]
      const lastName = names[names.length - 1]
      const { accessToken } = GoogleAuthProvider.credentialFromResult(result) as OAuthCredential
      const utm = utmServices.utmRead()
      const lang = textServices.language
      const body: any = { accessToken, firstName, lastName, utm, lang }

      if (birthday) {
        body.birthday = birthday;
      }

      const loginResponse = await axios.post(
        AUTH, body,
        {
          headers: { Authorization: `Bearer ${(result.user as any).accessToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
        }
      )
      setLoad(false)
      if (!loginResponse?.data?.profile?.termsOfUse) {
        gtag('event', 'sign_up', {
          event_label: 'SignUp - Google',
          method: 'Google'
        })
        MetaCAPIService.emitCompleteRegistration(result.user.email, firstName, lastName, '', window.location.href, 'Meta', MetaCAPIService.uuidv4())
        return setAllowCookies({
          callback: (data: { termsOfUse: boolean; subscribe: boolean; code?: string }) =>
            callback({ ...data, session: result, user: loginResponse?.data?.profile, prize: loginResponse?.data?.prize }),
          isAllow: true
        })
      } else {
        gtag('event', 'login', {
          event_label: 'Login - Google',
          method: 'Google'
        })
        dlTrackEvent('login', {
          method: 'google'
        })

        if (flowType === 'alreadyParticipate') return setAllowCookies({
          callback: () => callback({ session: result, user: loginResponse?.data?.profile, prize: loginResponse?.data?.prize }),
          isAllow: false
        })
      }
      callback({ session: result, user: loginResponse?.data?.profile, prize: loginResponse?.data?.prize })
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const code = (error as AxiosError).response?.data.code || 99999
        setLoginError(code)
      } else {
        const typedError = error as AuthError
        setError(typedError)
        const isAccountExistsError = typedError.code === 'auth/account-exists-with-different-credential';
        const errorKey = isAccountExistsError ? `${typedError.code}-google` : typedError.code;
        setFirebaseError(errorKey);
      }
    } finally {
      setLoad(false)
    }
  }

  const handleClick = () => {
    dlTrackEvent('user_interaction', {
      action: "click",
      element: 'google',
      section: "login"
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'Click - Google', value: 'Google' })

    const inBrowserApp = isInAppBrowser()
    if (inBrowserApp) return setAlertAppView(true);

    if (false as any || ["dosequis", "tecate", "mixx", "cristal", "heineken00"].includes(THEME)) {
      return goToUrlGoogle(null, utmServices.utmRead())
    }

    return login()
  }

  return (
    <>
      <AlertAppView open={alertAppView} setOpen={setAlertAppView} social='Google' />
      <div onClick={handleClick} className="cursor-pointer">
        <GoogleIcon className='google' />
      </div>
    </>
  )
}

export default Google
