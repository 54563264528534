import { IConfigModule } from "build/types/configs";

const config : IConfigModule = 
{
  "version": "2.0.0",
  "allowReferral": false,
  "filterPrizes": true,
  "comingSoon": false,
  "buttonsStart": [
    "signIn",
    "signUp",
    "facebook",
    "google"
  ],
  "positiveLogoUrls": [],
  "useSecondBackground": [
    "/error",
    "/end",
    "/social",
    "/album/:id",
    "/trivias",
    "/trivia",
    "/congrats",
    "/consumptions",
    "/prizes/congrats",
    "/prizes/error"
  ],
  "imageStart": "backgroundImage",
  "welcome": "ImgBackground",
  "legalAgeDate": true,
  "routesHome": [
    "SilverID"
  ],
  "routesProfile": [
    "information",
    "address",
    "history",
    "identificacion"
  ],
  "notAvailableFields": [
    "instagram",
    "interests",
    "consumptionCenter",
    "sign",
    "nickName"
  ],
  "backgroundWhite": true,
  "trivia": {
    "showIntroduction": false,
    "interestsRequired": false,
    "dontShowButtonExchange": false
  },
  "notShowFields": [
    "instagram"
  ],
  "startPopUp": true,
  "triviaQrPopUp": false,
  "dontShowSocialLogin": true,
  "showRulesInSocial": false,
  "album": {
    "Social": {
      "list": {
        "handleClick": true
      },
      "ranking": {
        "showSocial": ""
      },
      "profile": {
        "showSocial": ""
      }
    }
  },
  "modalBackgroundTrivias": true,
  "signUp": {
    "base": false
  },
  "profile": {
    "notShowButtonInformation": true,
    "showButtonPoints": true
  },
  "prizes": {
    "exchangeEnabled": true
  },
  "stepsNewVersion": true,
}

export default config;