import { Link } from "react-router-dom"
import textServices from "services/text-services"

textServices.findByText('error//title', { es: '', en: '' })
textServices.findByText('error//description', { es: '', en: '' })
textServices.findByText('error//button', { es: '', en: '' })

export const ERRORS = [
  {
    code: 1112,
    message: textServices.findByText('error/1112/title', { es: 'La fotografía excede el tamaño permitido', en: 'The photograph exceeds the allowed size' }),
    description: textServices.findByText('error/1112/description', { es: '4 Mb es el limite', en: '4 Mb is the limit' }),
    button: textServices.findByText('error/1112/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: 1114,
    message: textServices.findByText('error/1114/title', { es: 'Error al cargar tu imagen', en: 'Error uploading your image' }),
    description: textServices.findByText('error/1114/description', { es: 'Tu foto ha sido rechazada por:', en: 'Your photo has been rejected by:' }),
    button: textServices.findByText('error/1114/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: 10,
    message: textServices.findByText('error/10/title', { es: '¡Ups! Has ingresado un código OTP incorrecto', en: 'Oops! You have entered an incorrect OTP code' }),
    description: textServices.findByText('error/10/description', { es: <>Dele click en <b>REENVIAR</b> para recibir otro código e intentarlo de nuevo.</>, en: <>Click on <b>RESEND</b> to receive another code and try again.</> }),
    button: textServices.findByText('error/10/button', { es: 'Reenviar código', en: 'Resend code' })
  },
  {
    code: 11,
    message: textServices.findByText('error/11/title', { es: '¡Ups! Ingresaste un código erróneo', en: 'Oops! You have entered an incorrect OTP code' }),
    description: textServices.findByText('error/11/description', { es: 'No te preocupes e inténtalo de nuevo.', en: 'Don\'t worry and try again.' }),
    button: textServices.findByText('error/11/button', { es: <Link to="/redeem">VOLVER A INTENTAR</Link>, en: <Link to="/redeem">TRY AGAIN</Link> })
  },
  {
    code: 12,
    message: textServices.findByText('error/12/title', { es: '¡Ups! Ingresaste un código erróneo', en: 'Oops! You have entered an incorrect OTP code' }),
    description: textServices.findByText('error/12/description', { es: 'No te preocupes e inténtalo de nuevo.', en: 'Don\'t worry and try again.' }),
    button: textServices.findByText('error/12/button', { es: <Link to="/redeem">VOLVER A INTENTAR</Link>, en: <Link to="/redeem">TRY AGAIN</Link> })
  },
  {
    code: 15,
    message: textServices.findByText('error/15/title', { es: '¡Ups!, has alcanzado el máximo de canjeo en el día, vuelve mañana', en: 'Oops! You have reached the maximum redemption for today, come back tomorrow' }),
    description: textServices.findByText('error/15/description', { es: '', en: '' })
  },
  {
    code: 17,
    message: textServices.findByText('error/17/title', { es: 'Pss, este cupón ya no puede ser usado', en: 'Pss, this coupon can no longer be used' }),
    description: textServices.findByText('error/17/description', { es: 'No te preocupes e inténtalo de nuevo', en: 'Don\'t worry and try again' }),
    button: textServices.findByText('error/17/button', { es: <Link to="/redeem">VOLVER A INTENTAR</Link>, en: <Link to="/redeem">TRY AGAIN</Link> })
  },
  {
    code: 18,
    message: textServices.findByText('error/18/title', { es: '¡Ups! Este código ya fue utilizado antes', en: 'Oops! This code has been used before' }),
    description: textServices.findByText('error/18/description', { es: 'No te preocupes e inténtalo de nuevo', en: 'Don\'t worry and try again' }),
    button: textServices.findByText('error/18/button', { es: <Link to="/redeem">VOLVER A INTENTAR</Link>, en: <Link to="/redeem">TRY AGAIN</Link> })
  },
  {
    code: 50,
    message: textServices.findByText('error/50/title', { es: 'Tu cuenta aún no fue validada, te hemos enviado un email para avanzar', en: 'Your account has not yet been validated, we have sent you an email to move forward.' }),
    description: textServices.findByText('error/50/description', ''),
    button: textServices.findByText('error/50/button', { es: 'VOLVER A INTENTAR', en: 'TRY AGAIN' })
  },
  {
    code: "auth/wrong-password",
    message: textServices.findByText('error/auth/wrong-password/title', { es: 'Contraseña invalida', en: 'Invalid password' }),
    button: textServices.findByText('error/auth/wrong-password/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: "auth/user-not-found",
    message: textServices.findByText('error/auth/user-not-found/title', { es: 'Usuario inexistente', en: 'User not found' }),
    button: textServices.findByText('error/auth/user-not-found/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: "auth/email-already-in-use",
    message: textServices.findByText('error/auth/email-already-in-use/title', { es: 'Este mail ya se encuentra registrado', en: 'This email is already registered' }),
    description: textServices.findByText('error/auth/email-already-in-use/description', {
      es: (
        <>
          <p>Estás intentando registrarte usando tu <br /> CORREO ELECTRÓNICO.</p>
          <br />
          <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Cuenta de Facebook</b> o tu <b>Cuenta de Gmail</b>.</p>
        </>
      ),
      en: (
        <>
          <p>You are trying to register using your <br /> EMAIL.</p>
          <br />
          <p>Try logging in again using your <br /> <b>Facebook Account</b> or <b>Gmail Account</b>.</p>
        </>
      )
    }),
    button: textServices.findByText('error/auth/email-already-in-use/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: "auth/account-exists-with-different-credential",
    message: textServices.findByText('error/auth/account-exists-with-different-credential/title', { es: 'Este mail ya se encuentra registrado', en: 'This email is already registered' }),
    description: textServices.findByText('error/auth/account-exists-with-different-credential/description', {
      es: (
        <>
          <p>Estás intentando registrarte usando tu <br /> CORREO ELECTRÓNICO.</p>
          <br />
          <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Cuenta de Facebook</b> o tu <b>Cuenta de Gmail</b>.</p>
        </>
      ),
      en: (
        <>
          <p>You are trying to register using your <br /> EMAIL.</p>
          <br />
          <p>Try logging in again using your <br /> <b>Facebook Account</b> or <b>Gmail Account</b>.</p>
        </>
      )
    }),
    button: textServices.findByText('error/auth/account-exists-with-different-credential/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: "auth/account-exists-with-different-credential-facebook",
    message: textServices.findByText('error/auth/account-exists-with-different-credential-facebook/title', { es: 'Este mail ya se encuentra registrado', en: 'This email is already registered' }),
    description: textServices.findByText('error/auth/account-exists-with-different-credential-facebook/description', {
      es: (
        <>
          <p>Estás intentando registrarte usando tu <br /> CUENTA DE FACEBOOK.</p>
          <br />
          <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Correo</b> o tu <b>Cuenta de Gmail</b>.</p>
        </>
      ),
      en: (
        <>
          <p>You are trying to register using your <br /> FACEBOOK ACCOUNT.</p>
          <br />
          <p>Try logging in again using your <br /> <b>Email</b> or <b>Gmail Account</b>.</p>
        </>
      )
    }),
    button: textServices.findByText('error/auth/account-exists-with-different-credential-facebook/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: "auth/account-exists-with-different-credential-google",
    message: textServices.findByText('error/auth/account-exists-with-different-credential-google/title', { es: 'Este mail ya se encuentra registrado', en: 'This email is already registered' }),
    description: textServices.findByText('error/auth/account-exists-with-different-credential-google/description', {
      es: (
        <>
          <p>Estás intentando registrarte usando tu <br /> CUENTA DE GMAIL.</p>
          <br />
          <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Correo</b> o tu <b>Cuenta de Facebook</b>.</p>
        </>
      ),
      en: (
        <>
          <p>You are trying to register using your <br /> GMAIL ACCOUNT.</p>
          <br />
          <p>Try logging in again using your <br /> <b>Email</b> or <b>Facebook Account</b>.</p>
        </>
      )
    }),
    button: textServices.findByText('error/auth/account-exists-with-different-credential-google/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: "auth/email-not-exist",
    message: textServices.findByText('error/auth/email-not-exist/title', { es: 'Es necesario disponer de una cuenta con email', en: 'It is necessary to have an email account' }),
    description: textServices.findByText('error/auth/email-not-exist/description', { es: 'Intenta nuevamente con otra cuenta o red social', en: 'Try again with another account or social network' }),
    button: textServices.findByText('error/auth/email-not-exist/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: 106,
    message: textServices.findByText('error/106/title', { es: 'Es necesario disponer de una cuenta con email', en: 'It is necessary to have an email account' }),
    description: textServices.findByText('error/106/description', { es: 'Intenta nuevamente con otra cuenta o red social', en: 'Try again with another account or social network' }),
    button: textServices.findByText('error/106/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: "auth/network-request-failed",
    message: textServices.findByText('error/auth/network-request-failed/title', { es: '¡Ups! Parece que hay un inconveniente con la conexión', en: 'Oops! It seems there is a connection issue' }),
    button: textServices.findByText('error/auth/network-request-failed/button', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: 51,
    message: textServices.findByText('error/51/title', { es: '¡Ups! Tu cuenta de Facebook no tiene email', en: 'Oops! Your Facebook account has no email' }),
    description: textServices.findByText('error/51/description', { es: 'Necesitamos saber tu email para enviarte notificaciones', en: 'We need to know your email to send you notifications' })
  },
  {
    code: 58,
    message: textServices.findByText('error/58/title', { es: 'No se ha encontrado el usuario por el código de referido', en: 'User not found by referral code' }),
    description: textServices.findByText('error/58/description', { es: '', en: '' }),
    button: textServices.findByText('error/58/button', { es: 'VOLVER', en: 'BACK' })
  },
  {
    code: 59,
    message: textServices.findByText('error/59/title', { es: '¡Chin! No vamos para ese destino, pero no te preocupes', en: 'Oops! We do not go to that destination, but do not worry' }),
    description: textServices.findByText('error/59/description', { es: '', en: '' })
  },
  {
    code: 61,
    message: textServices.findByText('error/61/title', { es: 'Has alcanzado el límite de cambios en tu dirección', en: 'You have reached the limit of address changes' }),
    description: textServices.findByText('error/61/description', { es: 'Para cualquier duda ingresa:', en: 'For any questions, please enter:' })
  },
  {
    code: 62,
    message: textServices.findByText('error/62/title', { es: '¡Ups! Has ingresado la misma dirección', en: 'Oops! You have entered the same address' }),
    description: textServices.findByText('error/62/description', { es: '', en: '' })
  },
  {
    code: 63,
    message: textServices.findByText('error/63/title', { es: 'La dirección ingresada ya existe en otro usuario', en: 'The entered address already exists with another user' }),
    description: textServices.findByText('error/63/description', { es: '', en: '' }),
    button: textServices.findByText('error/63/button', { es: 'CORREGIR DIRECCIÓN', en: 'CORRECT ADDRESS' })
  },
  {
    code: 64,
    message: textServices.findByText('error/64/title', { es: '¡Oh no! El número parece incorrecto', en: 'Oh no! The number seems incorrect' }),
    description: textServices.findByText('error/64/description', { es: 'Por favor ingresa un número válido. Recuerda que debe ser un número de celular.', en: 'Please enter a valid number. Remember it must be a mobile number.' }),
    button: textServices.findByText('error/64/button', { es: 'CORREGIR NÚMERO DE CELULAR', en: 'CORRECT MOBILE NUMBER' })
  },
  {
    code: 66,
    message: textServices.findByText('error/66/title', { es: 'Es una lástima, tu cuenta ha sido bloqueada por 48 hrs. Mañana podrás intentarlo.', en: 'It’s a pity, your account has been blocked for 48 hrs. You can try again tomorrow.' }),
    description: textServices.findByText('error/66/description', {
      es: <>Esto es por seguridad debido a ingresos de códigos sospechosos. <br />No te apures si es un mal entendido nos pondremos en contacto contigo.</>,
      en: <>This is for security due to suspicious code entries. <br />Don’t worry if it’s a misunderstanding, we’ll contact you.</>
    }),
    button: textServices.findByText('error/66/button', { es: 'NECESITAS AYUDA?', en: 'NEED HELP?' })
  },
  {
    code: 67,
    message: textServices.findByText('error/67/title', { es: '¡Nos engañaste! has sido bloqueado de forma permanente', en: 'You tricked us! You have been permanently blocked' }),
    description: textServices.findByText('error/67/description', {
      es: <>Al haber sido bloqueado 3 veces hemos bloqueado tu cuenta. <br />No te apures si es un mal entendido nos pondremos en contacto contigo.</>,
      en: <>After being blocked 3 times, we have blocked your account. <br />Don’t worry if it’s a misunderstanding, we’ll contact you.</>
    }),
    button: textServices.findByText('error/67/button', { es: 'NECESITAS AYUDA?', en: 'NEED HELP?' })
  },
  {
    code: 68,
    message: textServices.findByText('error/68/title', { es: 'EL CURP ya existe en otra cuenta', en: 'The CURP already exists in another account' }),
    description: textServices.findByText('error/68/description', { es: 'Por el momento no puedes utilizar este CURP dado que ya existe en otra cuenta activa.', en: 'At the moment, you cannot use this CURP as it already exists in another active account.' })
  },
  {
    code: 69,
    message: textServices.findByText('error/69/title', { es: 'El celular ya existe en otra cuenta', en: 'The mobile number already exists in another account' }),
    description: textServices.findByText('error/69/description', { es: 'Por el momento no puedes utilizar este celular dado que ya existe en otra cuenta activa.', en: 'At the moment, you cannot use this mobile number as it already exists in another active account.' }),
    button: textServices.findByText('error/69/button', { es: 'CAMBIAR NÚMERO DE CELULAR', en: 'CHANGE MOBILE NUMBER' })
  },
  {
    code: 71,
    message: textServices.findByText('error/71/title', { es: '¡Ups!, parece que el código ingresado ha expirado', en: 'Oops! It seems the entered code has expired' }),
    description: textServices.findByText('error/71/description', { es: '', en: '' }),
    button: textServices.findByText('error/71/button', { es: 'Reenviar código', en: 'Resend code' })
  },
  {
    code: 72,
    message: textServices.findByText('error/72/title', { es: '¡Ups!, parece que el apodo ya existe', en: 'Oops! It seems the nickname already exists' }),
    description: textServices.findByText('error/72/description', { es: 'Prueba con un apodo distinto', en: 'Try a different nickname' }),
    button: textServices.findByText('error/72/button', { es: 'VOLVER', en: 'BACK' })
  },
  {
    code: 83,
    message: textServices.findByText('error/83/title', { es: 'Llegaste al máximo permitido de votaciones', en: 'You have reached the maximum allowed votes' }),
    description: textServices.findByText('error/83/description', { es: ' ', en: ' ' }),
    button: textServices.findByText('error/83/button', { es: 'VOLVER', en: 'BACK' })
  },
  {
    code: 100,
    message: textServices.findByText('error/100/title', { es: '¡Ups!, parece que hubo un problema con tu código de validación, por favor intenta nuevamente', en: 'Oops! There seems to be an issue with your validation code, please try again' }),
    description: textServices.findByText('error/100/description', { es: 'No hemos podido validar tu correo correctamente. Prueba de nuevo', en: 'We couldn’t validate your email correctly. Please try again' }),
    button: textServices.findByText('error/100/button', { es: 'VOLVER A INTENTAR', en: 'TRY AGAIN' })
  },
  {
    code: 102,
    message: textServices.findByText('error/102/title', { es: 'La cuenta ingresada es inexistente', en: 'The entered account does not exist' }),
    description: textServices.findByText('error/102/description', { es: 'No hemos podido validar tu correo correctamente. Prueba de nuevo', en: 'We couldn’t validate your email correctly. Please try again' }),
    button: textServices.findByText('error/102/button', { es: 'VOLVER A INTENTAR', en: 'TRY AGAIN' })
  },
  {
    code: 107,
    message: textServices.findByText('error/107/title', { es: 'La cuenta de correo ya está validada', en: 'The email account is already validated' }),
    button: textServices.findByText('error/107/button', { es: 'INICIAR SESIÓN', en: 'LOG IN' })
  },
  {
    code: 150,
    message: textServices.findByText('error/150/title', { es: '¡Chin! No vamos para ese destino, pero no te preocupes', en: 'Oops! We do not go to that destination, but do not worry' }),
    description: textServices.findByText('error/150/description', { es: '', en: '' })
  },
  {
    code: 153,
    message: textServices.findByText('error/153/title', { es: 'Es necesario completar tu dirección para poder acceder', en: 'You need to complete your address to access' }),
    description: textServices.findByText('error/153/description', { es: 'Debemos validar correctamente tu dirección', en: 'We need to correctly validate your address' }),
    button: textServices.findByText('error/153/button/text', { es: 'CAMBIAR MI DIRECCIÓN', en: 'CHANGE MY ADDRESS' })
  },
  {
    code: 251,
    message: textServices.findByText('error/251/title', { es: '¡Chin! Nos hemos quedado sin disponibilidad por el momento', en: 'Oops! We are currently out of availability' }),
    description: textServices.findByText('error/251/description', { es: '', en: '' })
  },
  {
    code: 252,
    message: textServices.findByText('error/252/title', { es: '¡Chin! No vamos para ese destino, pero no te preocupes', en: 'Oops! We do not go to that destination, but do not worry' }),
    description: textServices.findByText('error/252/description', { es: '', en: '' })
  },
  {
    code: 253,
    message: textServices.findByText('error/253/title', { es: `¡Ups!, parece que no tienes suficientes ${POINTS_NAME}`, en: `Oops! It seems you don’t have enough ${POINTS_NAME}` }),
    description: textServices.findByText('error/253/description', { es: '', en: '' })
  },
  {
    code: 254,
    message: textServices.findByText('error/254/title', { es: 'Pss, tienes que esperar para conseguir otro', en: 'Pss, you have to wait to get another' }),
    description: textServices.findByText('error/254/description', { es: '', en: '' })
  },
  {
    code: 351,
    message: textServices.findByText('error/351/title', { es: '¡Ups!, No existe la pagina', en: 'Oops! The page does not exist' }),
    description: textServices.findByText('error/351/description', { es: '', en: '' })
  },
  {
    code: 456,
    message: textServices.findByText('error/456/title', { es: 'El código debe tener al menos 4 caracteres', en: 'The code must be at least 4 characters long' }),
    description: textServices.findByText('error/456/description', { es: '', en: '' })
  },
  // -- TRIVIA --
  {
    code: 502,
    message: textServices.findByText('error/502/title', { es: '¡UPS! Ya has ganado esta trivia', en: 'Oops! You have already won this trivia' }),
    description: textServices.findByText('error/502/description', { es: 'Lamentablemente ya no está disponible porque ya las has completado. Prueba eligiendo otra trivia distinta.', en: 'Unfortunately, it is no longer available because you have already completed it. Try choosing a different trivia.' })
  },
  {
    code: 503,
    message: textServices.findByText('error/503/title', { es: <>¡UPS! <br />No tienes saldo suficiente</>, en: <>Oops! <br />You do not have enough balance</> }),
    description: textServices.findByText('error/503/description', { es: 'Recuerda que puedes intentarlo nuevamente, ¡Échale ganas!', en: 'Remember that you can try again, keep going!' })
  },
  {
    code: 504,
    message: textServices.findByText('error/504/title', { es: <>¡Ups! No hay suficiente stock del premio</>, en: <>Oops! There is not enough stock of the prize</> }),
    description: textServices.findByText('error/504/description', { es: <>Intente nuevamente mas tarde.</>, en: <>Please try again later.</> })
  },
  {
    code: 505,
    message: textServices.findByText('error/505/title', { es: '¡Oh no! fallaste', en: 'Oh no! You failed' }),
    description: textServices.findByText('error/505/description', { es: 'Recuerda que puedes intentarlo nuevamente, ¡Échale ganas!', en: 'Remember that you can try again, keep going!' }),
    button: textServices.findByText('error/505/button/text', { es: 'VOLVER A HOME', en: 'BACK TO HOME' })
  },
  {
    code: 506,
    message: textServices.findByText('error/506/title', { es: <>¡Ups! Te quedaste <br /> sin tiempo</>, en: <>Oops! You ran out of <br /> time</> }),
    description: textServices.findByText('error/506/description', { es: <></>, en: <></> }),
    button: textServices.findByText('error/506/button/text', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: 507,
    message: textServices.findByText('error/507/title', { es: '¡Ups! La trivia no es valida', en: 'Oops! The trivia is not valid' }),
    description: textServices.findByText('error/507/description', { es: <></>, en: <></> }),
    button: textServices.findByText('error/507/button/text', { es: 'VOLVER A HOME', en: 'BACK TO HOME' })

  },
  {
    code: 508,
    message: textServices.findByText('error/508/title', { es: '¡Ups! Esta trivia no esta en proceso', en: 'Oops! This trivia is not in process' }),
    description: textServices.findByText('error/508/description', { es: 'Es posible que haya terminado el tiempo', en: 'It is possible that the time has run out' })
  },
  {
    code: 509,
    message: textServices.findByText('error/509/title', { es: <>¡Ups! Esta trivia, no esta disponible en este momento</>, en: <>Oops! This trivia is not available at this time</> }),
    description: textServices.findByText('error/509/description', { es: <></>, en: <></> }),
    button: textServices.findByText('error/509/button/text', { es: 'VOLVER A HOME', en: 'BACK TO HOME' })
  },
  {
    code: 510,
    message: textServices.findByText('error/510/title', { es: '¡Ups! Nos falta tu dirección', en: 'Oops! We need your address' }),
    description: textServices.findByText('error/510/description', { es: 'Completa en tu perfil, tus datos de dirección para poder participar en esta trivia. Recuerda poner los datos correctos para poder enviarte tu premio a casa.', en: 'Complete your address details in your profile to participate in this trivia. Remember to put the correct information so we can send your prize home.' }),
    button: textServices.findByText('error/510/button', { es: 'VAMOS A COMPLETAR MIS DATOS', en: 'LET’S COMPLETE MY DETAILS' })
  },
  {
    code: 511,
    message: textServices.findByText('error/511/title', { es: '¡Ups! El premio no esta disponible en tu dirección', en: 'Oops! The prize is not available at your address' }),
    description: textServices.findByText('error/511/description', { es: 'Es posible que tengas que cambiar tu dirección', en: 'You may need to change your address' }),
    button: textServices.findByText('error/511/button/text', { es: 'Cambiar dirección', en: 'Change address' })
  },
  {
    code: 512,
    message: textServices.findByText('error/512/title', { es: '¡Ups! Llegaste tarde', en: 'Oops! You are late' }),
    description: textServices.findByText('error/512/description', { es: <></>, en: <></> }),
    button: textServices.findByText('error/512/button/text', { es: null, en: null })
  },
  {
    code: 513,
    message: textServices.findByText('error/513/title', { es: '¡Ups! Parece que este link no es el correcto', en: 'Oops! It seems this link is not correct' }),
    description: textServices.findByText('error/513/description', { es: <></>, en: <></> })
  },
  {
    code: 514,
    message: textServices.findByText('error/514/title', { es: '¡Ups! Esta trivia está muy concurrida', en: 'Oops! This trivia is very crowded' }),
    description: textServices.findByText('error/514/description', { es: 'Esperate tantito a que bajen los participantes, mientras puedes checar otra trivia para jugar.', en: 'Wait a little for the participants to decrease, meanwhile, you can check out another trivia to play.' })
  },

  // -- SURVEY --
  {
    code: 859,
    message: textServices.findByText('error/859/title', { es: <>¡Ups! Esta encuesta, no está disponible en este momento</>, en: <>Oops! This survey is not available at this time</> }),
    description: textServices.findByText('error/859/description', { es: <></>, en: <></> }),
    button: textServices.findByText('error/859/button/text', { es: 'VOLVER A HOME', en: 'BACK TO HOME' })
  },
  {
    code: 860,
    message: textServices.findByText('error/860/title', { es: '¡UPS! Ya has ganado esta encuesta', en: 'Oops! You have already won this survey' }),
    description: textServices.findByText('error/860/description', { es: 'Lamentablemente ya no está disponible porque ya las has completado. Prueba eligiendo otra encuesta distinta.', en: 'Unfortunately, it is no longer available because you have already completed it. Try choosing a different survey.' })
  },

  // -- MEMORAMA -- 
  {
    code: 901,
    message: textServices.findByText('error/901/title', { es: '¡Ups! Este memorama se encuentra en proceso en tu cuenta.', en: 'Oops! This memory game is in process on your account.' }),
    description: textServices.findByText('error/901/description', { es: 'Estate atento a las nuevas dinámicas que tendremos para ti.', en: 'Stay tuned for the new activities we will have for you.' }),
    button: textServices.findByText('error/901/button/text', { es: 'Ver más dinámicas', en: 'See more activities' })
  },
  {
    code: 902,
    message: textServices.findByText('error/902/title', { es: '¡Ups! Este memorama ya ha sido respondido en tu cuenta.', en: 'Oops! This memory game has already been answered on your account.' }),
    description: textServices.findByText('error/902/description', { es: 'Estate atento a las nuevas dinámicas que tendremos para ti.', en: 'Stay tuned for the new activities we will have for you.' }),
    button: textServices.findByText('error/902/button/text', { es: 'Ver más dinámicas', en: 'See more activities' })
  },
  {
    code: 905,
    message: textServices.findByText('error/905/title', { es: '¡Qué pena!', en: 'What a shame!' }),
    description: textServices.findByText('error/905/description', { es: 'Has agotado tus chances pero no temas, puedes volver a intentarlo.', en: 'You have exhausted your chances but do not worry, you can try again.' }),
    button: textServices.findByText('error/905/button/text', { es: 'Volver a empezar', en: 'Start over' })
  },
  {
    code: 906,
    message: textServices.findByText('error/906/title', { es: '¡Qué pena!', en: 'What a shame!' }),
    description: textServices.findByText('error/906/description', { es: 'Se te acabó el tiempo', en: 'You ran out of time' }),
    button: textServices.findByText('error/906/button/text', { es: 'Volver a empezar', en: 'Start over' })
  },
  {
    code: 907,
    message: textServices.findByText('error/907/title', { es: '¡Ups! Este memorama no está en proceso', en: 'Oops! This memory game is not in process' }),
    description: textServices.findByText('error/907/description', { es: 'Es posible que ya no se encuentre más disponible, estate atento a las nuevas dinámicas que tendremos para ti.', en: 'It may no longer be available, stay tuned for the new activities we will have for you.' }),
    button: textServices.findByText('error/907/button/text', { es: 'Ver más dinámicas', en: 'See more activities' })
  },
  {
    code: 908,
    message: textServices.findByText('error/908/title', { es: '¡Ups! Este memorama no está en proceso', en: 'Oops! This memory game is not in process' }),
    description: textServices.findByText('error/908/description', { es: 'Es posible que ya no se encuentre más disponible, estate atento a las nuevas dinámicas que tendremos para ti.', en: 'It may no longer be available, stay tuned for the new activities we will have for you.' }),
    button: textServices.findByText('error/908/button/text', { es: 'Ver más dinámicas', en: 'See more activities' })
  },
  {
    code: 909,
    message: textServices.findByText('error/909/title', { es: '¡Ups! Este memorama no está en proceso', en: 'Oops! This memory game is not in process' }),
    description: textServices.findByText('error/909/description', { es: 'Es posible que ya no se encuentre más disponible, estate atento a las nuevas dinámicas que tendremos para ti.', en: 'It may no longer be available, stay tuned for the new activities we will have for you.' }),
    button: textServices.findByText('error/909/button/text', { es: 'Ver más dinámicas', en: 'See more activities' })
  },
  {
    code: 910,
    message: textServices.findByText('error/910/title', { es: '¡Ups! Nos falta tu dirección', en: 'Oops! We need your address' }),
    description: textServices.findByText('error/910/description', { es: 'Completa en tu perfil, tus datos de dirección para poder participar en este memorama. Recuerda poner los datos correctos para poder enviarte tu premio a casa.', en: 'Complete your address details in your profile to participate in this memory game. Remember to put the correct information so we can send your prize home.' }),
    button: textServices.findByText('error/910/button/text', { es: 'VAMOS A COMPLETAR MIS DATOS', en: 'LET’S COMPLETE MY DETAILS' })
  },
  {
    code: 911,
    message: textServices.findByText('error/911/title', { es: '¡Ups! El premio no está disponible en tu dirección activa', en: 'Oops! The prize is not available at your active address' }),
    description: textServices.findByText('error/911/description', { es: 'Estate atento a las nuevas dinámicas que tendremos para ti.', en: 'Stay tuned for the new activities we will have for you.' }),
    button: textServices.findByText('error/911/button/text', { es: 'Ver más dinámicas', en: 'See more activities' })
  },
  {
    code: 912,
    message: textServices.findByText('error/912/title', { es: '¡Ups! Este memorama ya llegó a su máximo de usos.', en: 'Oops! This memory game has reached its maximum number of uses.' }),
    description: textServices.findByText('error/912/description', { es: 'Estate atento a las nuevas dinámicas que tendremos para ti.', en: 'Stay tuned for the new activities we will have for you.' }),
    button: textServices.findByText('error/912/button/text', { es: 'Ver más dinámicas', en: 'See more activities' })
  },
  {
    code: 913,
    message: textServices.findByText('error/913/title', { es: '¡Ups! Al parecer no completaste el memorama.', en: 'Oops! It seems you did not complete the memory game.' }),
    description: textServices.findByText('error/913/description', { es: 'Pero no temas, puedes volver a intentarlo', en: 'But do not worry, you can try again' }),
    button: textServices.findByText('error/913/button/text', { es: 'Volver a empezar', en: 'Start over' })
  },
  {
    code: 900,
    message: textServices.findByText('error/900/title', { es: <>¡Aún no puede<br />subir su foto!</>, en: <>You cannot upload your photo yet!</> }),
    description: textServices.findByText('error/900/description', { es: <>Falta que termine de completar su perfil</>, en: <>You still need to complete your profile</> }),
    button: textServices.findByText('error/900/button/text', { es: '¡VAMOS A COMPLETARLO!', en: 'LET’S COMPLETE IT!' })
  },
  {
    code: 1006,
    message: textServices.findByText('error/1006/title', { es: <>¡Ups! El código <br /> parece inválido</>, en: <>Oops! The code <br /> seems invalid</> }),
    description: textServices.findByText('error/1006/description', { es: <>Es posible que el código ingresado no exista <br /> o ya haya sido utilizado por otro usuario.</>, en: <>The code entered may not exist <br /> or may have already been used by another user.</> })
  },
  {
    code: 1108,
    message: textServices.findByText('error/1108/title', { es: <>Dinámica finalizada</>, en: <>Activity ended</> }),
    description: textServices.findByText('error/1108/description', { es: 'Esta dinámica ya tiene sus ganadores.', en: 'This activity already has its winners.' }),
    button: textServices.findByText('error/1108/button/text', { es: 'Volver', en: 'Back' })
  },
  {
    code: 1116,
    message: textServices.findByText('error/1116/title', { es: <>Espera tantito</>, en: <>Wait a moment</> }),
    description: textServices.findByText('error/1116/description', { es: <>Lo sentimos, no es posible cargar en tan corto tiempo tus consumos, te esperamos de vuelta más tarde.</>, en: <>Sorry, it is not possible to load your consumption in such a short time, we will wait for you later.</> }),
    button: textServices.findByText('error/1116/button/text', { es: 'Volver', en: 'Back' })
  },
  {
    code: 1301,
    message: textServices.findByText('error/1301/title', { es: <>No se ha podido acceder al perfil del usuario</>, en: <>Could not access user profile</> }),
    description: textServices.findByText('error/1301/description', { es: <>intente nuevamente.</>, en: <>try again.</> })
  },
  {
    code: 1302,
    message: textServices.findByText('error/1302/title', { es: <>El perfil no dispone de fotos para obtener</>, en: <>The profile has no photos to retrieve</> }),
    description: textServices.findByText('error/1302/description', { es: <>intente nuevamente con un perfil que tenga fotos para subir.</>, en: <>try again with a profile that has photos to upload.</> })
  },
  {
    code: 1303,
    message: textServices.findByText('error/1303/title', { es: <>Cuenta ya vinculada</>, en: <>Already linked account</> }),
    description: textServices.findByText('error/1303/description', { es: <>La cuenta seleeccionada ya existe vinculada con otra centa.</>, en: <>The selected account already exists linked to another account.</> }),
    button: textServices.findByText('error/1303/button/text', { es: 'Volver a intentar', en: 'Try again' })

  },
  {
    code: 'user_denied',
    message: textServices.findByText('error/user_denied/title', { es: <>Acceso denegado</>, en: <>Access denied</> }),
    description: textServices.findByText('error/user_denied/description', { es: <>Intente nuevamente dándole permisos a Instagram.</>, en: <>Try again by giving permissions to Instagram.</> })
  },
  {
    code: 1506,
    message: textServices.findByText('error/1506/title', { es: '¡Ups! Parece que el tipo de archivo no es válido', en: 'Oops! It seems the file type is not valid' }),
    description: textServices.findByText('error/1506/description', { es: 'Asegúrate de que el archivo sea una imagen de tipo png, jpeg o jpg', en: 'Make sure the file is a png, jpeg or jpg image' })
  },
  {
    code: 79,
    message: textServices.findByText('error/79/title', { es: 'Ya has votado esta foto', en: 'You have already voted for this photo' }),
    description: textServices.findByText('error/79/description', { es: 'Puedes votar por otra foto', en: 'You can vote for another photo' })
  },
  {
    code: 858,
    message: textServices.findByText('error/858/title', { es: 'Te han quedado preguntas sin responder', en: 'You have unanswered questions' }),
    description: textServices.findByText('error/858/description', { es: 'Intente nuevamente', en: 'Try again' }),
    button: textServices.findByText('error/858/button/text', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: 9628,
    message: textServices.findByText('error/9628/title', { es: '¡Qué pena!', en: 'What a shame!' }),
    description: textServices.findByText('error/9628/description', { es: 'Has usado tus 3 chances pero no temas, puedes volver a intentarlo.', en: 'You have used your 3 chances but do not worry, you can try again.' }),
    button: textServices.findByText('error/9628/button/text', { es: 'Volver a empezar', en: 'Start over' })
  },
  {
    code: 6695,
    message: textServices.findByText('error/6695/title', { es: '¡Ups!, parece que hubo un problema', en: 'Oops, there seems to have been a problem.' }),
    description: textServices.findByText('error/6695/description', { es: 'Para continuar, por favor ingresa tu apodo', en: 'To continue, please enter your nickname' }),
    button: textServices.findByText('error/6695/button/text', { es: 'VOLVER', en: 'BACK TO' })
  },
  {
    code: 9631,
    message: textServices.findByText('error/9631/title', { es: '¡Qué pena!', en: 'What a shame!' }),
    description: textServices.findByText('error/9631/description', { es: 'Has agotado tus chances pero no temas, puedes volver a intentarlo.', en: 'You have exhausted your chances but do not worry, you can try again.' }),
    button: textServices.findByText('error/9631/button/text', { es: 'Volver a empezar', en: 'Start over' })
  },
  {
    code: 9629,
    message: textServices.findByText('error/9629/title', { es: '¡Qué pena!', en: 'What a shame!' }),
    description: textServices.findByText('error/9629/description', { es: 'Se te acabó el tiempo', en: 'You ran out of time' }),
    button: textServices.findByText('error/9629/button/text', { es: 'Volver a empezar', en: 'Start over' })
  },
  {
    code: 9630,
    message: textServices.findByText('error/9630/title', { es: '¡Gracias por escribirnos! Recibimos tu mensaje.', en: 'Thank you for writing to us! We have received your message.' }),
    description: textServices.findByText('error/9630/description', { es: 'Danos un par de días para contestarte y estamos seguros que te vamos a poder ayudar. Recuerda que te llegará la respuesta por mail.', en: 'Give us a couple of days to reply and we are sure we can help you. Remember that you will receive the response by email.' })
  },
  {
    code: 99999,
    message: textServices.findByText('error/99999/title', { es: 'Ha ocurrido un error', en: 'An error has occurred' }),
    description: textServices.findByText('error/99999/description', { es: '', en: '' }),
    button: textServices.findByText('error/99999/button/text', { es: <>¿ES UN ERROR?<br /> PARA CUALQUIER DUDA INGRESA AQUI</>, en: <>IS IT AN ERROR?<br /> FOR ANY QUESTIONS CLICK HERE</> })
  },
  // album ticket
  {
    code: 1214,
    message: textServices.findByText('error/1214/title', { es: '¡Espera!', en: 'Wait!' }),
    description: textServices.findByText('error/1214/description', { es: 'No puedes avanzar si no tienes los datos completos DE TU NÚMERO DE CLIENTE.', en: 'You cannot proceed if you do not have your CUSTOMER NUMBER details complete.' }),
    button: textServices.findByText('error/1214/button/text', { es: 'COMPLETAR', en: 'COMPLETE' })
  },
  // COLLECTIONS ERRORS
  {
    code: 7000,
    message: textServices.findByText('error/7000/title', { es: 'hubo un error!', en: 'There was an error!' }),
    description: textServices.findByText('error/7000/description', { es: 'No se ha encontrado la etiqueta, por favor intente nuevamente.', en: 'The tag was not found, please try again.' }),
    button: textServices.findByText('error/7000/button/text', { es: 'Volver a intentar', en: 'Try again' })
  },
  {
    code: 7001,
    message: textServices.findByText('error/7001/title', { es: 'YA TIENES ESA ETIQUETA', en: 'YOU ALREADY HAVE THAT LABEL' }),
    description: textServices.findByText('error/7001/description', { es: 'inténtalo de nuevo ingresando otra distinta.', en: 'Please try again by entering a different one.' }),
    button: textServices.findByText('error/7001/button/text', { es: 'Volver a intentar', en: 'Try again' })
  },
  // LINEUP
  {
    code: 8001,
    message: textServices.findByText('error/8001/title', { es: '¡Atención! Has elegido dos artistas que tocan en el mismo horario', en: 'Attention! You have chosen two artists playing at the same time slot' }),
    description: textServices.findByText('error/8001/description', { es: 'No es necesario que hagas cambios si no quieres, pero ten en cuenta que se superponen esos artistas y talvez tengas que elegir uno o correr para ver ambos.', en: "You don't need to make changes if you don't want to, but keep in mind that these artists overlap and you may have to choose one or run to see both." }),
    button: textServices.findByText('error/8001/button/text', { es: 'Comprendo', en: 'I understand' }),
    subButton: textServices.findByText('error/8001/subButton/text', { es: 'Modificar selección', en: 'Modify selection' }),
  },
  {
    code: 8002,
    message: textServices.findByText('error/8002/title', { es: <>¡Listo!<br />Has creado tu lineup</>, en: <>Done!<br />You have created your lineup.</> }),
    description: textServices.findByText('error/8002/description', {
      es: <>Ya puedes chequear los artistas que seleccionaste y editar tu lista cuando quieras.<br /><br /><br /><b>¿Tienes las notificaciones activas?</b><br />Te invitamos a activarlas desde el menú <b className="underline">“Mi información”</b> para poder avisarte cuando tus artistas ya estén en vivo.</>,
      en: <>You can now check the artists you have selected and edit your list at any time.<br /><br /><br /><b>Do you have notifications active?</b><br />We invite you to activate them from the <b className="underline">“My info”</b> menu to be notified when your artists are already live.</>
    }),
    button: textServices.findByText('error/8002/button/text', { es: 'Entendido', en: 'Understood' }),
  },
  {
    code: 8003,
    message: textServices.findByText('error/8002/title', {
      es: <>¡Listo!<br />Has editado tu lineup</>, en: <>Done!<br />You have edit your lineup.</>
    }),
    description: textServices.findByText('error/8002/description', {
      es: <><b>¿Tienes las notificaciones activas?</b><br />Te invitamos a activarlas desde el menú <b className="underline">“Mi información”</b> para poder avisarte cuando tus artistas ya estén en vivo.</>,
      en: <><b>Do you have notifications active?</b><br />We invite you to activate them from the <b className="underline">“My info”</b> menu to be notified when your artists are already live.</>
    }),
    button: textServices.findByText('error/8002/button/text', { es: 'Continuar', en: 'Continue' }),
  }
]

export const prettyError = (code?: number | string) => {
  const defaultMessage = ERRORS.find((error) => error.code === 99999)?.message
  const errorMesg = ERRORS.find((error) => error.code === code)
  return errorMesg?.message ? errorMesg?.message : defaultMessage
}

export const prettyErrorDescription = (code?: number | string) => {
  const defaultMessage = ERRORS.find((error) => error.code === 99999)?.description
  const errorMesg = ERRORS.find((error) => error.code === code)
  return errorMesg?.description ? errorMesg?.description : defaultMessage
}

export const prettyErrorButton = (code?: number | string) => {
  const errorMesg = ERRORS.find((error) => error.code === code)
  return errorMesg?.button
}

export const prettyErrorSubButton = (code?: number | string) => {
  const errorMesg = ERRORS.find((error) => error.code === code)
  return errorMesg?.subButton
}
